AFRAME.registerComponent("ui-option", {
    schema: {
        chairOptionsEl: {type: "selector"},
        option: {type: "int"},
        borderEl: {type: "selector"},
        hitboxEl: {type: "selector"},
    },

    init: function () {
        this.bindMethods();

        this.clickSoundEl = document.getElementById("ui-click-sound");

        this.data.hitboxEl.setAttribute("visible", false);

        if (this.data.option == 1) this.borderColor = "#3F9CFF";
        else this.borderColor = "#ccc";

        this.data.borderEl.setAttribute("material", "color", this.borderColor);

        this.createAnimations();

        this.data.hitboxEl.addEventListener("raycaster-intersected", () => {
            if (!this.isCurrentlySelectedOption()) this.data.borderEl.emit("cursorIn");
        });
        this.data.hitboxEl.addEventListener("raycaster-intersected-cleared", () => {
            if (!this.isCurrentlySelectedOption()) this.data.borderEl.emit("cursorOut");
        });

        this.data.chairOptionsEl.addEventListener("changeOption", this.onChangeOption);

        this.data.hitboxEl.addEventListener("click", () => {
            this.clickSoundEl.components.sound.playSound();
            this.data.chairOptionsEl.emit("changeOption", {newOption: this.data.option});
        });
    },

    createAnimations: function () {
        this.data.borderEl.setAttribute("animation__cursorIn", {
            startEvents: "cursorIn",
            property: "components.material.material.color",
            type: "color",
            to: "#fc6c38",
            dur: 200,
        });

        this.data.borderEl.setAttribute("animation__cursorOut", {
            startEvents: "cursorOut",
            property: "components.material.material.color",
            type: "color",
            to: this.borderColor,
            dur: 200,
        });
    },

    isCurrentlySelectedOption: function () {
        return (
            this.data.chairOptionsEl.components["chair-options"].selectedOption == this.data.option
        );
    },

    onChangeOption: function (evt) {
        if (evt.detail.newOption == this.data.option) this.borderColor = "#3F9CFF";
        else this.borderColor = "#ccc";

        this.data.borderEl.components.material.material.color.set(this.borderColor);
        this.data.borderEl.setAttribute("animation__cursorOut", "to", this.borderColor);
    },

    bindMethods: function () {
        this.onChangeOption = this.onChangeOption.bind(this);
    },
});
